@layer base {
  .main * {
  box-sizing: border-box;
}

.main {
  display: flex;
  position: fixed;
  z-index: var(--z-index-dev-tools);
  top: 2px;
  left: 2px;
  transition: 0.2s ease-in-out;
  font-size: 16px;
}

.main__collapsed {
  transform: translateX(calc(-100% + 16px));
}

.main__collapsed .buttonStatus::after {
  transform: rotateY(180deg);
}

.main h3 {
  margin-top: 2px;
}

.content {
  width: 332px;
  padding: 10px;
  border-radius: 4px;
  background-color: var(--sk-color-grey-800);
  color: var(--sk-color-white);
  font-size: 16px;
}

.button {
  width: 38px;
  height: 38px;
  border: 0;
  border-radius: 4px;
  background-color: var(--sk-color-grey-800);
  color: var(--sk-color-white);
  line-height: normal;
  cursor: pointer;
}

.buttonStatus {
  position: relative;
  width: 332px;
  font-size: 16px;
}

.buttonStatus::after {
  content: "<";
  display: inline-block;
  position: absolute;
  top: 8px;
  right: 4px;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
}

.buttonEdit {
  margin-left: 2px;
}

.buttonEdit::after {
  content: "✎";
  display: inline-block;
  transform: rotateY(180deg);
  font-size: 24px;
}

}