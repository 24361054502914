@layer base {
  .main {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
}

.debugLine {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--sk-space-4);
}

.debugLine > div {
  display: flex;
  gap: var(--sk-space-8);
}

.debugLine span {
  min-width: fit-content;
  font-weight: bold;
}

.debugLine code {
  width: 90%;
  word-break: break-all;
}

.debugLine a {
  color: inherit;
}

}