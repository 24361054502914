@layer modules, ui, base;
@layer base {
  .DebugAlgoliaRequests_main__DCPWA > div {
  display: flex;
  align-items: center;
  gap: var(--sk-space-4);
}

.DebugAlgoliaRequests_main__DCPWA h4 {
  margin: var(--sk-space-4) 0;
}

.DebugAlgoliaRequests_main__DCPWA button {
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
}

.DebugAlgoliaRequests_main__DCPWA button:hover {
  text-decoration: underline;
}

}
@layer base {
  .DebugInfoDisplay_main__sSG9K {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
}

.DebugInfoDisplay_debugLine__4jp6o {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--sk-space-4);
}

.DebugInfoDisplay_debugLine__4jp6o > div {
  display: flex;
  gap: var(--sk-space-8);
}

.DebugInfoDisplay_debugLine__4jp6o span {
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-weight: bold;
}

.DebugInfoDisplay_debugLine__4jp6o code {
  width: 90%;
  word-break: break-all;
}

.DebugInfoDisplay_debugLine__4jp6o a {
  color: inherit;
}

}
@layer base {
  .DebugMenu_main__gmbO_ * {
  box-sizing: border-box;
}

.DebugMenu_main__gmbO_ {
  display: flex;
  position: fixed;
  z-index: var(--z-index-dev-tools);
  top: 2px;
  left: 2px;
  transition: 0.2s ease-in-out;
  font-size: 16px;
}

.DebugMenu_main__collapsed__sQHBh {
  transform: translateX(calc(-100% + 16px));
}

.DebugMenu_main__collapsed__sQHBh .DebugMenu_buttonStatus__bNpmp::after {
  transform: rotateY(180deg);
}

.DebugMenu_main__gmbO_ h3 {
  margin-top: 2px;
}

.DebugMenu_content__8S_Ox {
  width: 332px;
  padding: 10px;
  border-radius: 4px;
  background-color: var(--sk-color-grey-800);
  color: var(--sk-color-white);
  font-size: 16px;
}

.DebugMenu_button__vsizd {
  width: 38px;
  height: 38px;
  border: 0;
  border-radius: 4px;
  background-color: var(--sk-color-grey-800);
  color: var(--sk-color-white);
  line-height: normal;
  cursor: pointer;
}

.DebugMenu_buttonStatus__bNpmp {
  position: relative;
  width: 332px;
  font-size: 16px;
}

.DebugMenu_buttonStatus__bNpmp::after {
  content: "<";
  display: inline-block;
  position: absolute;
  top: 8px;
  right: 4px;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
}

.DebugMenu_buttonEdit__gfPnm {
  margin-left: 2px;
}

.DebugMenu_buttonEdit__gfPnm::after {
  content: "✎";
  display: inline-block;
  transform: rotateY(180deg);
  font-size: 24px;
}

}
