@layer base {
  .main > div {
  display: flex;
  align-items: center;
  gap: var(--sk-space-4);
}

.main h4 {
  margin: var(--sk-space-4) 0;
}

.main button {
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
}

.main button:hover {
  text-decoration: underline;
}

}